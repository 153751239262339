import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
 
})
export class LayoutComponent implements OnInit {

 

  ngOnInit(): void {
    // Intentionally left empty for future initialization logic// Empty ngOnInit method
  }

}
