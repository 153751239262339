

export const environment = {
  production: false,


  API_URL: "https://devapi-mhh.approd.ca/",


};



