import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  currentType: string = '';
  userData: any[] = []; 
  public ownerHighlighted = false;
  public contractorHighlighted = false;
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData:any;
  graphData: any;
  months: number = 6;
  currentUser:any;
  analyticsData:any;
  finalCount:any;
  clientCounts:number=0;
  careGiverCounts:number=0;
  bookingCount:number=0;
  incomeCount:number=0;
  @ViewChild("template2") template2: TemplateRef<any>;

  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private modalService: BsModalService,



    ) { }


  ngOnInit(): void {
    this.onClickHomeOwner('owner')
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    if (this.page) {
      if(this.contractor) {
        this.contractorCurrentPage = this.page
      
      } else {
        this.ownerCurrentPage = this.page
      }
    }
    this.getCount();
    this.getCountofhome();
  }

  propertySubscriptionIds: any;

  imgURL: any;

  reset() {
    this.imgURL = "../../../assets/icons/img-thumb.svg";
    
  }

  modalRef: BsModalRef;
  owner: any;
  user:any;
  selectedOwner:any;
  createService(template2: TemplateRef<any>,owner:any) {
 
  this.selectedOwner = owner;
  this.modalRef = this.modalService.show(template2, { class: 'modal-lg' });
    
  
  }

  homeOwnersData:any;
  page: number;

  ownersData: any
  contractorData: any;
  notFound: boolean = false;
  itemsPerPage: number = 10;
  totalCount: number;
  currentPage: number = 1 ;
  contractorCurrentPage: number = 1;
  ownerCurrentPage: number = 1;





  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

 registeredhomeowners: number = 0;
  registeredcontractors: number = 0;
  registeredcontractorsList: any[] = [];
  registeredhomeownerList: any[] = [];
  contractor: boolean = false;
  typeUser : any
  promotionServicesOption: boolean = false;
  subscriptionOption: boolean = false;
  contractorCount: any;
  onClickContractor(type: any) {
    
    this.currentType = type;
    this.contractor = true;
    this.promotionServicesOption = true;
    this.subscriptionOption = true;

    this.ownerHighlighted = type === 'owner';
    this.contractorHighlighted = type === 'contractor';
    this.dashboardService.getcontractordata(this.contractorCurrentPage, this.itemsPerPage).subscribe((res: any) => {
      if (res.status === 200) {
        this.contractorCount = res.totalCount
        this.registeredcontractorsList = res.output;
        this.selectedOwner = this.registeredcontractorsList.length > 0 ? this.registeredcontractorsList[0] : null;
      }
    });
  }

  convertDateTimeFormat(date: any) {
    const formattedDate = this.datepipe.transform(new Date(date), 'MMM dd, yyyy');
    
    const formattedDateTime = `${formattedDate}`;

  
    return formattedDateTime;
  }

  shouldDisplayAddressOnNextLine(address: string): string {
    if (address) {
      const words = address.split(' ');
      if (words.length > 10) {
        return words.join('<br>');
      }
    }
    return address ? address : 'N/A';
  }
  pageChangedOwner(event: PageChangedEvent): void {
    this.ownerCurrentPage = event.page;
      this.onClickHomeOwner('owner');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);

  }

  pageChangedContract(event: PageChangedEvent): void {
    console.log('event', event)
    this.contractorCurrentPage = event.page;
      this.onClickContractor('contractor');
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);

  }
  ownerCount: any
  onClickHomeOwner(type: any) {
    this.currentType = type;
    this.contractor = false;
    this.promotionServicesOption = false;
    this.subscriptionOption = true;

    this.ownerHighlighted = type === 'owner';
    this.contractorHighlighted = type === 'contractor';
    this.dashboardService.gethomeownerdata(this.ownerCurrentPage, this.itemsPerPage).subscribe((res: any) => {
        if (res.status === 200) {
             this.ownerCount = res.totalCount
            this.registeredhomeownerList = res.output;
            this.selectedOwner = this.registeredhomeownerList.length > 0 ? this.registeredhomeownerList[0] : null;
        }
    });
}

 getCount() {
  this.dashboardService.getDashboardCount().subscribe((res: any) => {
    if (res.status == 200) {
      this.registeredcontractors = res.totalCount 
     
   
    }
  })
}

getCountofhome() {
  this.dashboardService.getDashboardCountofhome().subscribe((res: any) => {
    if (res.status == 200) {
      this.registeredhomeowners = res.totalCount
     
   
    }
  })
}
}
