import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-view',
    template: `<router-outlet></router-outlet>`
})

export class ViewsComponent implements OnInit {

    constructor() {
      
         // Initialize properties or perform operations as needed 
    }

    ngOnInit() {
        //for future use
    }
}