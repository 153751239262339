import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'users/upload', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'admins/add-service', data);
    }

    getServices(page,pagelimit, searchKey){
        const url = `${this.url}admins/list-service?page=${page}&pagelimit=${pagelimit}`;
          return this.http.get(url);
    }

   

    deleteService(data:any){
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'admins/delete-service', httpOptions);
    }

    getServiceBySearch(data:any){
        return this.http.post(this.url + 'admins/search', data);
    }

  


    editService(data:any){
        return this.http.post(this.url + 'admins/edit-service', data);
    }
}