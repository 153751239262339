<app-layout></app-layout>

<div class="content">
 

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-12 total-counts mb-3" (click)="onClickHomeOwner('owner')">
        <mat-card class="total-count owner-card" [ngClass]="{ 'highlighted': ownerHighlighted }">
          <div class="card-content">
            <img class="card-img-top" src="../../../assets/images/homeowner.png" alt="Devices Icon" height="60" width="60">
          </div>
          <div class="card-body">
            <h1 style="text-align: center;">{{ registeredhomeowners }}</h1>
            <h5 class="card-title text-center">Home Owners</h5>
          </div>
        </mat-card>
      </div>
    
      <div class="col-lg-6 col-md-6 col-sm-12 total-counts mb-3" (click)="onClickContractor('contractor')">
        <mat-card class="total-count contractor-card" [ngClass]="{ 'highlighted': contractorHighlighted }">
          <div class="card-content">
            <img class="card-img-top" src="../../../assets/images/serviceprovider.png" alt="Active Age Group Icon" height="60" width="60">
          </div>
          <div class="card-body">
            <h1 style="text-align: center;">{{ registeredcontractors }}</h1>
            <h5 class="card-title text-center">Contractors</h5>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  

  
  

  <div class="skill-tabs d-flex justify-content-between align-items-top mt-2">
    <div class="table-container">
      <table class="table mt-2" aria-label="Description of the table">
        <thead>
          <tr>
            <th scope="col" class="font-weight-bold" style="font-size: 16px; color: black;">Sr.No</th>
            <th scope="col" class="font-weight-bold" style="font-size: 16px;  color: black;">Name</th>
            <th scope="col" class="font-weight-bold" style="font-size: 16px;  color: black;">{{ contractor ?
              'Email/Contact' : 'Email' }}</th>
            <th scope="col" class="font-weight-bold" style="font-size: 16px;  color: black;">Created Date</th>

            <th scope="col" class="font-weight-bold" style="font-size: 16px;  color: black;">Location</th>
           
            <th scope="col" class="font-weight-bold" style="font-size: 16px; color: black;" *ngIf="contractor">Promotion
              Services</th>
            <th scope="col" class="font-weight-bold" style="font-size: 16px;  color: black;">Subscription</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let owner of (contractor ? registeredcontractorsList : registeredhomeownerList); let i = index">
            <tr>
              <td>{{contractor ? ((contractorCurrentPage - 1) * itemsPerPage + i + 1) : ((ownerCurrentPage - 1) * itemsPerPage + i + 1)}}.</td>
              <td style="color: tomato;cursor: pointer;" (click)="createService(template2, owner)">{{ owner?.firstName ? owner?.firstName : 'N/A' }} {{ owner?.lastName ? owner?.lastName : 'N/A' }}</td>
              <td>
                {{ owner?.email ? owner?.email : 'N/A' }}
                <br *ngIf="contractor && owner?.phoneNo" /> 
                {{ contractor && owner?.countryCode ? owner?.countryCode : '' }}  {{ contractor && owner?.phoneNo ? owner?.phoneNo : '' }}
              </td>
              <td>{{ owner?.createdAt ? convertDateTimeFormat(owner?.createdAt) : 'N/A' }} </td>
              <td>
                {{ contractor ? (owner?.locations[0]?.city ? owner?.locations[0]?.city + ', ' +
                owner?.locations[0]?.country : 'N/A') : (owner?.propertyDetails[0]?.locations?.city ?
                owner?.propertyDetails[0]?.locations?.city + ', ' + owner?.propertyDetails[0]?.locations?.country :
                'N/A') }}
              </td>
              <td *ngIf="contractor">{{ owner?.businessPromotedPlanType ? owner?.businessPromotedPlanType : 'N/A' }}</td>
              <td >
                <ng-container *ngIf="owner?.isSubscribe && (owner?.propertySubscriptionIds?.length || owner?.serviceSubscriptionIds?.length); else notSubscribed">
                  {{ contractor ? owner?.serviceSubscriptionIds[0]?.planType : owner.propertySubscriptionIds[0]?.planType }}
                </ng-container>
                <ng-template #notSubscribed>N/A</ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
        

      </table>
    </div>
   
  </div>



  
  <pagination *ngIf="!notFound && contractor &&  contractorCount  > itemsPerPage" [boundaryLinks]="true"
    [totalItems]="contractorCount" [itemsPerPage]="itemsPerPage" [(ngModel)]="contractorCurrentPage"
    (pageChanged)="pageChangedContract($event)" previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10"
    firstText="&laquo;" lastText="&raquo;">
  </pagination>

 
  <pagination *ngIf="!notFound && !contractor && ownerCount > itemsPerPage" [boundaryLinks]="true"
    [totalItems]="ownerCount" [itemsPerPage]="itemsPerPage" [(ngModel)]="ownerCurrentPage"
    (pageChanged)="pageChangedOwner($event)" previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10"
    firstText="&laquo;" lastText="&raquo;">
  </pagination>


</div>


<ng-template #template2 let-owner="owner">
  <div class="modal-header">
    <h2 class="modal-title">{{ currentType === 'contractor' ? 'Contractor' : 'Home Owner' }} Details</h2>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide(); reset()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <div *ngIf="selectedOwner">

      <div class="section-container mb-4" *ngIf="currentType === 'contractor'">
        <div class="d-flex justify-content-center align-items-center">
          <ng-container *ngIf="selectedOwner?.profilePicture; else defaultImage">
            <img [src]="selectedOwner?.profilePicture" alt="{{ selectedOwner?.serviceName }}" height="110" width="130" style="margin-top: -8px;">
          </ng-container>
          <ng-template #defaultImage>
            <img src="../../../assets/images/serviceprovider.png" alt="Service Provider" height="110" width="130" style="margin-top: -8px;">
          </ng-template>
        </div>
        
      </div>

      <div class="text-left">
        <p>
          <strong><span style="color: black;font-size: 15px;">Name:&nbsp;</span></strong> 
          <span style="color: gray;font-size: 15px;">{{ selectedOwner.firstName || 'N/A' }} {{ selectedOwner.lastName || 'N/A' }}</span>
        </p>
        
        <p><strong><span style="color: black;font-size: 15px;">Email:&nbsp;</span></strong> <span style="color: gray;font-size: 15px;">{{ selectedOwner.email }}</span></p>

        <ng-container *ngIf="currentType === 'contractor'">
          <p><strong><span style="color: black;font-size: 15px;">Contact No:&nbsp;</span></strong> <span style="color: gray;font-size: 15px;">{{ selectedOwner.countryCode }} {{ selectedOwner.phoneNo || 'N/A' }}</span></p>
        </ng-container>

        <ng-container *ngIf="currentType !== 'contractor'">
          <p>
            <strong><span style="color: black;font-size: 15px;">{{ currentType === 'contractor' ? 'Contractor Address:' : 'Home Owner Address:' }}</span></strong>
            <span style="color: gray;font-size: 15px;">
              {{ selectedOwner?.propertyDetails[0]?.locations?.address || 'N/A' }}
            </span>
          </p>
        </ng-container>

        <ng-container *ngIf="currentType === 'contractor'">
          <div>
            <p><strong><span style="color: black;font-size: 15px;">Business Name:&nbsp;</span></strong> <span style="color: gray;font-size: 15px;">{{ selectedOwner.businessName || 'N/A' }}</span></p>
            <p><strong><span style="color: black;font-size: 15px;">Business Description:&nbsp;</span></strong> <span style="color: gray;font-size: 15px;">{{ selectedOwner.businessDescription || 'N/A' }}</span></p>
            <p style="line-height: 1.2;">
              <strong>
                <span style="color: black; font-size: 15px;">Business Location:</span>
              </strong>
              <span style="color: gray; font-size: 15px;">
                {{ selectedOwner.businessLocation?.address || 'N/A' }},
                {{ selectedOwner.businessLocation?.city || 'N/A' }},
                {{ selectedOwner.businessLocation?.country || 'N/A' }}
              </span>
            </p>
            

            <p>
              <strong><span style="color: black; font-size: 15px;">Service Locations And Radius:&nbsp;</span></strong>
            </p>
            <div style="color: gray; font-size: 15px; padding-left: 20px;">
              <div *ngIf="selectedOwner.locations && selectedOwner.locations.length > 0; else noData">
                <div *ngFor="let location of selectedOwner.locations; let i = index" style="margin-bottom: 20px; line-height: 1.5; display: flex; align-items: flex-start;">
                  <div style="flex-shrink: 0;">
                    <span style="margin-right: 10px;">{{ i + 1 }}. </span>
                  </div>
                  <div style="flex-grow: 1;">
                    <span style="display: inline-block;">
                      {{ location.address || 'N/A' }}, {{ location.city || 'N/A' }}, {{ location.country || 'N/A' }}
                    </span>
                    <div>
                      <span>
                        (<strong style="color: black;">Radius:</strong> {{ location.radius || 'N/A' }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <div>N/A</div>
              </ng-template>
            </div>
            
            
            
            
          </div>
        </ng-container>

      </div>
    </div>

    <ng-template #noData>
      <p>No data available</p>
    </ng-template>
  </div>
</ng-template>









