import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(data:any){
        return this.http.post(this.url + "appList/getDashboardAnalytics", data );
    }


    getDownloadAppList(){
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }


    getActiveUsers(){
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }


    // getActiveUsers()

    getTotalForms(){
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data){
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount(){
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id:any){
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }

    getDashboardCount(){
        const params = new HttpParams().set('role','3');
        return this.http.get(this.url + "users/list-profile", {params})
    }
    getDashboardCountofhome(){
        const params = new HttpParams().set('role', '2');
        return this.http.get(this.url + "users/list-profile", { params });
    }


    gethomeownerdata(page,pageLimit ) {
        
        return this.http.get(`${this.url}users/list-profile?role=2&page=${page}&pageLimit=${pageLimit}`);
    }

    getcontractordata(page, pageLimit) {
       
        return this.http.get(`${this.url}users/list-profile?role=3&page=${page}&pageLimit=${pageLimit}`)
       
    }



   

}