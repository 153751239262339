import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    authService;
    router;
    constructor(authService: AuthService, router: Router) {
        this.authService = authService;
        this.router = router;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const currentUser = this.authService.currentUserValue;
    
        if (currentUser) {
            return true; 
        } else {
            return false; 
        }
    }
    
}