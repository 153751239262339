import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit {

  constructor() {
//intialisation
   }

  ngOnInit(): void {
//intentionally left space for future use
  }

}
