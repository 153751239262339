<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Dashboard</h1>
          
        </ng-container>
        <h1 *ngIf="manageCaregiversHeading">Preventive Measures</h1>
        <h1 *ngIf="manageCaregiversotherHeading">Add Preventive Measure</h1>

        <h1 *ngIf="manageClientHeading">Manage Businesses</h1>
        <h1 *ngIf="manageServicesHeading">Manage Services</h1>
        <h1 *ngIf="manageTutorialHeading">Manage Tutorial</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading">Manage Services</h1>
        <h1 *ngIf="manageSubCategoriesHeading">Manage Sub Categories</h1>
        <h1 *ngIf="manageTasksHeading">Home Health Tasks</h1>
        <h1 *ngIf="managePreventiveHeading">Preventive Measures</h1>
        
        <h1 *ngIf="manageTasksViewHeading">Home Health Tasks</h1>


        


       

        <h1 *ngIf="manageLocalHeading">Home Health Assessment</h1>

        
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="texHeading">Home Health Assessment</h1>
        
    </div>


 
</div>


<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>