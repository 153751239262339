<div class="sidebar">
  <div class="logo mt-2"><img src="../../../assets/images/loginImage.png" alt="logo" style=" object-fit: contain;"></div>
 
  <div class="header-links">
    
    <h6 style="font-size: 19px;
    margin-left: 44px;">Home <strong>Health</strong> Check</h6>

    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/dashboard.svg" alt="">
        <img class="white-icon" src="../../../assets/little white/dashboard.svg" alt="" height="24" width="24">
    </span>Dashboard</a>

    <a routerLink="/services" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Services.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Services.svg" alt="" height="24" width="24">
    </span>Manage Services</a>

   
    <a routerLink="/manage/homehealth" routerLinkActive="active" class="icon-text">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage convenience.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage convenience.svg" alt="" height="24" width="24">
      </span>
      <span class="text" style="margin-left: 1px;">Home Health Assessment</span>
    </a>
    
    

    <a routerLink="/preventivemeasure" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage tutorial.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/MFcare Icons/White/Manage tutorial.svg" alt="" height="24" width="24">
  </span>Preventive Measures</a>

    




    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
      
        <img class="black-icon" src="../../../assets/little/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/little/Log Out.svg" alt="" height="24" width="24">
    </span>Logout</a>



    
<ng-template #template>
  <div class="modal-header">
     
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p>Are you sure you want to Logout?</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;margin-right: -112px;">No</button>
          <button class="btn btn-blue" (click)="confirm()" style="background-color: #FD8575;margin-right: -1px;">Yes</button>
      </div>
  </div>

</ng-template>




  </div>
</div>